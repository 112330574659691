<template>
  <div class="relative w-full">
    <div class="w-full bg-white rounded-[10px]">
      <header
        class="w-full flex justify-between items-center border-b border-greyscale-7 px-5 py-4"
      >
        <h3 class="text-primary font-[800]">Create a bank account</h3>

        <button class="inline w-[16px] h-[18px]" @click="closeModal">
          <close-icon />
        </button>
      </header>

      <div class="px-5 pt-5 pb-8">
        <Listbox v-model="selectedAccount">
          <div class="relative mt-1">
            <ListboxLabel class="text-text-secondary text-sm font-medium"
              >Select currency</ListboxLabel
            >
            <ListboxButton
              class="relative w-full h-10 border overflow-hidden border-[#e2e2e2] rounded-[5px] focus:outline-primary text-sm text-primary placeholder:text-sm mt-2 text-left px-4 py-2"
            >
              <span v-if="selectedAccount">{{ selectedAccount.name }}</span>
              <span v-else>Choose a currency </span>
              <span
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
              >
                <up-down-arrow-icon />
              </span>
            </ListboxButton>

            <ListboxOptions
              class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 z-10 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm"
            >
              <div>
                <ListboxOption
                  v-for="currency in supportedCurrencies"
                  :key="currency.code"
                  v-slot="{ active, selected }"
                  :value="currency"
                  as="template"
                >
                  <li
                    class="relative cursor-default select-none py-2 pl-10 pr-4"
                    :class="{
                      'bg-greyscale-2 text-primary': active,
                      'text-text-primary': !active,
                    }"
                  >
                    <span
                      :class="{
                        'font-medium': selected,
                        'font-normal': !selected,
                      }"
                      >{{ currency.name }}</span
                    >
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 left-0 flex items-center pl-3"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </div>
            </ListboxOptions>
          </div>
        </Listbox>

        <form
          v-if="selectedAccount"
          class="w-full flex flex-col gap-y-3 mt-3"
          @submit="onSubmit"
        >
          <app-input
            v-if="selectedAccount.code === 'NGN'"
            v-bind="formFields.businessRegistrationDate"
            type="date"
            name="businessRegistrationDate"
            :error-message="errors.businessRegistrationDate"
            placeholder="Enter your business registration date"
            label="Business registration date"
            required
          />

          <template v-for="requirement in requirements" :key="requirement.name">
            <app-input
              v-if="requirement.type === 'string'"
              type="text"
              :name="requirement.name"
              :error-message="errors[requirement.name]"
              v-bind="formFields[requirement.name]"
              :placeholder="`Enter your ${requirement.name}`"
              :label="requirement.name.toUpperCase()"
              :pattern="requirement.regex || null"
              :title="`Please enter a valid ${requirement.name}`"
              required
            />

            <template v-else-if="requirement.type === 'address'">
              <app-input
                v-bind="formFields.address_line_1"
                :error-message="errors.address_line_1"
                label="Address Line 1"
                type="text"
                name="address_line_1"
                placeholder="Enter address line 1"
                required
              />
              <app-input
                v-bind="formFields.address_line_2"
                :error-message="errors.address_line_2"
                label="Address Line 2"
                type="text"
                name="address_line_2"
                placeholder="Enter address line 2"
              />
              <app-input
                v-bind="formFields.address_city"
                :error-message="errors.address_city"
                label="Address City"
                type="text"
                name="address_city"
                placeholder="Enter address city"
                required
              />

              <app-input
                v-bind="formFields.address_state"
                :error-message="errors.address_state"
                label="Address State"
                type="text"
                name="address_state"
                placeholder="Enter address state"
                required
              />

              <combo-input
                label="Country"
                :data="countries"
                :error-message="errors.address_country"
                @select="handleSelectedCountry"
              />
            </template>
          </template>

          <app-button
            :loading="submitting"
            :disabled="!selectedAccount || submitting"
            size="lg"
            variant="primary"
            type="submit"
            >Create Account</app-button
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, computed } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  ListboxLabel,
} from "@headlessui/vue";
import { SupportedBankingCurrency } from "@/types";
import * as yup from "yup";
import { useForm } from "vee-validate";
import { countriesList } from "@/helpers/countries";
import { SelectInputData } from "../shared/type";

const props = defineProps<{
  closeModal: () => void;
  supportedCurrencies: SupportedBankingCurrency[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAccount: (request: Record<string, any>) => void;
  submitting: boolean;
}>();

const selectedAccount = ref<SupportedBankingCurrency>();

type FormFields = Record<string, string> & {
  businessRegistrationDate: string;
  nin?: string;
  bvn?: string;
  address_line_1?: string;
  address_line_2?: string;
  address_city?: string;
  address_state?: string;
  address_country?: string;
};

const countries = computed(() => {
  return countriesList.map((it) => ({
    label: it.name,
    value: it.code,
  }));
});

const requirements = computed(() => {
  return selectedAccount.value?.requirements || [];
});

const validationSchema = computed(() => {
  return yup.object({
    ...(selectedAccount.value?.requirements?.length
      ? selectedAccount.value.requirements.reduce(
          (acc, requirement) => {
            if (requirement.type === "address") {
              return {
                ...acc,
                [`${requirement.name}_line_1`]: yup
                  .string()
                  .required("Line 1 is required"),
                [`${requirement.name}_line_2`]: yup.string().optional(),
                [`${requirement.name}_city`]: yup
                  .string()
                  .required("City is required"),
                [`${requirement.name}_state`]: yup
                  .string()
                  .required("State is required"),
                [`${requirement.name}_country`]: yup
                  .string()
                  .required("Country is required"),
              };
            }
            return {
              ...acc,
              [requirement.name]: requirement.regex
                ? yup.string().matches(new RegExp(requirement.regex), {
                    message: `Please enter a valid ${requirement.name}`,
                    excludeEmptyString: true,
                  })
                : yup.string().required(`${requirement.name} is required`),
            };
          },
          {} as Record<string, yup.StringSchema>,
        )
      : {}),
    businessRegistrationDate:
      selectedAccount.value?.code === "NGN"
        ? yup.string().required("Business registration date is required")
        : yup.string().optional(),
  });
});

const { errors, handleSubmit, defineInputBinds, setFieldValue } =
  useForm<FormFields>({
    validationSchema: validationSchema,
  });

const onSubmit = handleSubmit(async (values) => {
  if (selectedAccount.value) {
    await props.createAccount({
      currency: selectedAccount.value.code,
      account_owner_info:
        selectedAccount.value.code === "NGN"
          ? {
              business_registration_date: values.businessRegistrationDate,
              nin: values.nin,
              bvn: values.bvn,
              address: values.address_line_1
                ? {
                    line_1: values.address_line_1,
                    line_2: values.address_line_2,
                    city: values.address_city,
                    state: values.address_state,
                    country: values.address_country,
                  }
                : undefined,
            }
          : undefined,
    });
  }
});

const handleSelectedCountry = (val: SelectInputData) => {
  setFieldValue("address_country", val.value);
};

const formFields = reactive<
  Record<keyof FormFields, ReturnType<typeof defineInputBinds>>
>({
  businessRegistrationDate: defineInputBinds("businessRegistrationDate"),
  nin: defineInputBinds("nin"),
  bvn: defineInputBinds("bvn"),
  address_line_1: defineInputBinds("address_line_1"),
  address_line_2: defineInputBinds("address_line_2"),
  address_city: defineInputBinds("address_city"),
  address_state: defineInputBinds("address_state"),
  address_country: defineInputBinds("address_country"),
});
</script>
