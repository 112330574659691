<template>
  <div
    class="w-full bg-[#646464] bg-deals bg-cover bg-no-repeat bg-center rounded-[10px] flex flex-col justify-center items-center px-5 py-14 gap-y-5"
  >
    <h2 class="text-2xl text-[28px] font-medium text-white text-center">
      Over $500k in discounts on the best SaaS software
    </h2>

    <div
      class="w-full max-w-[716px] h-12 rounded-[10px] bg-white px-3 md:px-5 py-2 flex gap-x-2 md:gap-x-3 items-center"
    >
      <div>
        <search-icon />
      </div>
      <slot name="search"></slot>
    </div>

    <!-- <div
      class="w-full flex justify-center items-center gap-x-2 lg:gap-x-4 gap-y-2 flex-wrap"
    >
      <div
        class="bg-white/20 text-white rounded-[90px] px-4 py-2 flex justify-center items-center gap-x-2 text-sm"
      >
        <search-icon class="fill-white" />
        Slack
      </div>

      <div
        class="bg-white/20 text-white rounded-[90px] px-4 py-2 flex justify-center items-center gap-x-2 text-sm"
      >
        <search-icon class="fill-white" />
        Intercom
      </div>

      <div
        class="bg-white/20 text-white rounded-[90px] px-4 py-2 flex justify-center items-center gap-x-2 text-sm"
      >
        <search-icon />
        Google workspace
      </div>
    </div> -->
  </div>
</template>

<script setup lang="ts"></script>
