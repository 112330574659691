<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="flex w-[110px] h-[35px] items-center rounded-[20px] bg-greyscale-2 px-3 py-2 gap-x-2 text-primary text-sm"
        :class="modelValue ? 'justify-between' : 'justify-end'"
      >
        <div v-if="modelValue" class="flex">
          <asset-type :asset="modelValue.currency" />
        </div>

        <arrow-down-icon class="min-w-[13px]" />
      </MenuButton>
    </div>
    <MenuItems
      class="absolute right-0 mt-2 w-[160px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-[20] max-h-[170px] overflow-y-auto"
    >
      <div class="p-1 flex flex-col gap-y-1">
        <MenuItem v-for="item in currencies" :key="item.currency">
          <button
            class="flex w-[100px] items-center gap-x-2 px-2 py-2"
            @click="handleSelect(item)"
          >
            <AssetType :asset="item.currency" />
            <span v-if="item.blockchain" class="text-sm"
              >({{ item.blockchain }})</span
            >
          </button>
        </MenuItem>
      </div>
    </MenuItems>
  </Menu>
</template>

<script lang="ts" setup>
import { Menu, MenuItems, MenuItem, MenuButton } from "@headlessui/vue";
import { SwapCurrency } from "./types";
import { onMounted } from "vue";

interface Props {
  modelValue: SwapCurrency | null;
  currencies: SwapCurrency[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "update:modelValue", value: SwapCurrency | null): void;
}>();

const handleSelect = (val: SwapCurrency) => {
  emit("update:modelValue", val);
};

onMounted(() => {
  if (!props.modelValue && props.currencies.length) {
    emit("update:modelValue", props.currencies[0]);
  }
});
</script>
