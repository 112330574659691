export const useDownloader = () => {
  const fromBlob = (data: Blob, contentDisposition = "") => {
    const file = window.URL.createObjectURL(data);
    const filename =
      contentDisposition.split("filename=")[1].split(";")[0] || "download";
    const sanitizedFilename = filename.replace(/[^a-zA-Z0-9-_. ]/g, "");
    const link = document.createElement("a");
    link.href = file;
    link.setAttribute("download", sanitizedFilename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return { fromBlob };
};
