<template>
  <div
    class="w-full bg-white border border-[#f0f0f0] py-5 rounded-[10px] flex flex-col gap-y-5"
  >
    <div class="w-full border-b border-[#f0f0f0] px-5 min-h-[175px]">
      <div class="flex justify-between items-start mb-4">
        <div class="w-[55px] h-auto rounded-[8px] overflow-hidden">
          <img :src="avatar" :alt="name" />
        </div>

        <app-button
          v-if="!state"
          variant="primary"
          size="sm"
          @click="openDealDetails"
        >
          Redeem
        </app-button>
        <app-button
          v-if="state && state === 'PENDING_ACTION'"
          variant="primary"
          size="sm"
          @click="openDealDetails"
        >
          Redeem
        </app-button>
      </div>
      <div class="mb-4">
        <h3 class="text-base font-bold text-black mb-1">{{ name }}</h3>
        <p class="text-sm text-primary/60">{{ description }}</p>
        <div
          v-if="state"
          class="text-xs bg-tertiary/10 rounded-[5px] text-tertiary w-fit px-4 py-2 font-medium mt-2"
        >
          {{ snakeCaseToWords(toTitleCase(state)) }}
        </div>
      </div>
    </div>
    <div class="px-5">
      <div class="w-full flex flex-col gap-y-2">
        <div
          v-for="it in computedRewards"
          :key="it.description"
          class="text-[13px] text-primary flex gap-x-2"
        >
          <reward-icon class="min-w-fit mt-[2px]" />
          <div>
            <span v-if="it.title" class="font-semibold"> {{ it.title }}: </span>
            <span>{{ it.description }}</span>
          </div>
        </div>
      </div>

      <!-- <div class="text-sm text-primary flex gap-x-2 items-center">
        <small-mark-icon class="fill-black" />
        <div>{{ requirement }}</div>
      </div> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { snakeCaseToWords, toTitleCase } from "@/helpers";
import { computed } from "vue";

export interface Props {
  dealId: string;
  name: string;
  state?: string;
  avatar: string;
  description: string;
  rewards: string[];

  openDealDetails: () => void;
}

const props = defineProps<Props>();

const computedRewards = computed(() => {
  return props.rewards.map((reward) => {
    const value = reward.split(":");

    if (value.length > 1) {
      return {
        title: reward.split(":")[0],
        description: reward.split(":")[1],
      };
    }

    return {
      title: null,
      description: reward,
    };
  });
});
</script>
