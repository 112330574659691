import apiClient, {
  bankingUrl,
  cryptoUrl,
  useRequestFilters,
  useRequestOptions,
} from "@/helpers/apiClient";
import {
  AssetTransactionsSummaryResponse,
  AssetsResponse,
  AvailableAssetsResponse,
  PaginatedResponse,
  QueryKeys,
  RequestOptions,
  TotalAssetValueResponse,
  CryptoTransactionResponse,
  AccountTransactionsSummaryResponse,
} from "@/types";
import { useQuery } from "@tanstack/vue-query";
import { Ref } from "vue";

export const useGetAssets = (
  enabled: boolean = true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: Record<string, Ref<any>>,
) =>
  useQuery<PaginatedResponse<AssetsResponse>>({
    queryKey: [QueryKeys.ASSETS, ...Object.values(filters || {})],
    queryFn: () =>
      apiClient
        .get(cryptoUrl(`assets/corporate?${useRequestFilters(filters)}`))
        .then((res) => res.data.data),
    staleTime: 0,
    enabled,
  });

export const useGetAsset = (id: string, enabled: boolean = true) =>
  useQuery<AssetsResponse>({
    queryKey: [QueryKeys.ASSET, id],
    queryFn: () =>
      apiClient.get(cryptoUrl(`assets/${id}`)).then((res) => res.data.data),
    enabled,
  });

export const useGetAssetTransactions = (
  id: string,
  options: RequestOptions,
  enabled: boolean = true,
) =>
  useQuery<PaginatedResponse<CryptoTransactionResponse>>({
    queryKey: [
      QueryKeys.ASSET_TRANSACTIONS,
      id,
      options.page,
      options.limit,
      ...Object.values(options.filters || {}),
    ],
    queryFn: () =>
      apiClient
        .get(
          cryptoUrl(`assets/${id}/transactions?${useRequestOptions(options)}`),
        )
        .then((res) => res.data.data),
    enabled,
  });

export const useGetAssetTransactionsSummary = (
  id: string,
  enabled: boolean = true,
) =>
  useQuery<AssetTransactionsSummaryResponse>({
    queryKey: [QueryKeys.ASSET_TRANSACTIONS_SUMMARY, id],
    queryFn: () =>
      apiClient
        .get(cryptoUrl(`assets/${id}/transactions/summary`))
        .then((res) => res.data.data),
    enabled,
  });

export const useGetAccountTransactionsSummary = (
  id: string,
  enabled: boolean = true,
) =>
  useQuery<AccountTransactionsSummaryResponse>({
    queryKey: [QueryKeys.ASSET_TRANSACTIONS_SUMMARY, id],
    queryFn: () =>
      apiClient
        .get(bankingUrl(`accounts/${id}/transaction-summary`))
        .then((res) => res.data.data),
    enabled,
  });

export const useGetAssetsTotalValue = (enabled: boolean = true) =>
  useQuery<TotalAssetValueResponse>({
    queryKey: [QueryKeys.TOTAL_ASSETS_VALUE],
    queryFn: () =>
      apiClient
        .get(cryptoUrl(`assets/corporate/total-usd-value`))
        .then((res) => res.data.data),
    enabled,
  });

export const useGetAvailableAssets = (enabled: boolean = true) =>
  useQuery<Array<AvailableAssetsResponse>>({
    queryKey: [QueryKeys.AVAILABLE_ASSETS],
    queryFn: () =>
      apiClient.get(cryptoUrl("data/supported-assets")).then((res) => res.data),
    enabled,
  });
