<template>
  <app-layout title="Deals" description="">
    <section class="w-full px-4 md:px-8 lg:pl-14 lg:pr-8">
      <deal-search>
        <template #search>
          <input
            v-model="dealsQueryModel"
            type="text"
            class="w-full h-full bg-transparent focus:outline-none active:outline-none"
            placeholder="Search for SaaS software like “Microsoft Azure”"
          />
        </template>
      </deal-search>

      <div class="w-full mt-7">
        <page-loader v-if="isLoading || isFetching" />
        <div v-else-if="data" class="w-full">
          <div
            class="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 mt-8"
          >
            <deal-card
              v-for="deal in data.items"
              :key="deal.id"
              :deal-id="deal.id"
              :name="deal.name"
              :state="deal.business_deal_state"
              :avatar="deal.logo_url"
              :description="deal.description"
              :rewards="deal.benefits"
              :open-deal-details="() => openDealDetails(deal)"
            />
          </div>

          <app-pagination
            v-if="data.items.length > 0"
            :total-items="data.total_items || 0"
            :current-page="currentPage"
            :per-page="perPage"
            @change="handlePaginationChange"
          />
        </div>

        <error-component v-else-if="error" :message="'Error Fetching deal'" />
      </div>
    </section>

    <app-modal
      v-if="selectedDeal"
      :is-open="isDealDetailsOpen"
      :handle-close="closeDealDetails"
      size="lg"
    >
      <deal-details-wrapper
        :close-modal="closeDealDetails"
        :deal="selectedDeal"
      />
    </app-modal>
  </app-layout>
</template>

<script setup lang="ts">
import { PaginateChangeParams } from "@/components/shared/table/table.props";
import { useGetBusinessDeals } from "@/data-access/deals";
import { BusinessDealsResponse } from "@/types";
import { debounce } from "lodash";
import { ref, watch } from "vue";

const isDealDetailsOpen = ref(false);
const selectedDeal = ref<BusinessDealsResponse | null>(null);
const currentPage = ref(1);
const perPage = ref(10);
const dealsQuery = ref("");
const dealsQueryModel = ref("");

const { isLoading, isFetching, data, error } = useGetBusinessDeals({
  page: currentPage,
  limit: perPage,
  filters: {
    name: dealsQuery,
  },
});

const openDealDetails = (dealId: BusinessDealsResponse) => {
  selectedDeal.value = dealId;
  isDealDetailsOpen.value = true;
};

const closeDealDetails = () => {
  selectedDeal.value = null;
  isDealDetailsOpen.value = false;
};

const handlePaginationChange = (params: PaginateChangeParams) => {
  currentPage.value = params.currentPage;
  perPage.value = params.perPage;
};

watch(
  dealsQueryModel,
  debounce((val) => {
    if (val.length >= 4) {
      dealsQuery.value = val;
    }
    if (val.length === 0) {
      dealsQuery.value = "";
    }
  }, 500),
);
</script>
