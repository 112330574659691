<template>
  <div class="w-full">
    <div class="text-text-primary text-sm mb-3">{{ label }}</div>
    <input
      ref="inputRef"
      class="bg-transparent text-[21px] text-text-primary border-0 active:border-0 focus:outline-none"
      :value="inputValue"
      :required="required"
      :disabled="disabled"
      placeholder="0.00"
      type="text"
      inputmode="decimal"
      @input="handleInput"
      @keydown="validateInput"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";

interface Props {
  modelValue?: number;
  label?: string;
  required?: boolean;
  disabled?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: "update:modelValue", value: number | undefined): void;
}>();

const inputValue = ref("");
const inputRef = ref<HTMLInputElement | null>(null);

const MAX_DECIMAL_PLACES = 2;

const formatAmount = (value: string): string => {
  // Remove any non-digit characters except decimal point
  let cleanValue = value.replace(/[^\d.]/g, "");

  // Ensure only one decimal point
  const parts = cleanValue.split(".");
  cleanValue = parts[0] + (parts.length > 1 ? "." + parts[1] : "");

  // Split into integer and decimal parts
  const [integerPart, decimalPart] = cleanValue.split(".");

  // Format integer part with commas
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine integer and decimal parts
  if (decimalPart !== undefined) {
    return `${formattedInteger}.${decimalPart.slice(0, MAX_DECIMAL_PLACES)}`;
  }

  return formattedInteger;
};

const setCursorPosition = (cursorPosition: number) => {
  if (!inputRef.value) return;

  setTimeout(() => {
    inputRef.value?.setSelectionRange(cursorPosition, cursorPosition);
  }, 0);
};

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const cursorPosition = target.selectionStart || 0;
  const oldValue = inputValue.value;

  const formattedValue = formatAmount(target.value);

  inputValue.value = formattedValue;

  // Calculate new cursor position
  const oldCommaCount = (oldValue.slice(0, cursorPosition).match(/,/g) || [])
    .length;
  const newCommaCount = (
    formattedValue.slice(0, cursorPosition).match(/,/g) || []
  ).length;
  const newPosition = cursorPosition + (newCommaCount - oldCommaCount);

  setCursorPosition(newPosition);

  if (formattedValue === "") {
    emit("update:modelValue", undefined);
  } else {
    const numericValue = parseFloat(formattedValue.replace(/,/g, ""));
    emit("update:modelValue", isNaN(numericValue) ? undefined : numericValue);
  }
};

const validateInput = (event: KeyboardEvent) => {
  const allowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
    "Tab",
  ];

  if (!allowedKeys.includes(event.key)) {
    event.preventDefault();
    return;
  }

  // Prevent multiple decimal points
  if (event.key === "." && inputValue.value.includes(".")) {
    event.preventDefault();
  }

  // Prevent more than MAX_DECIMAL_PLACES after the decimal point
  if (inputValue.value.includes(".")) {
    const [, decimalPart] = inputValue.value.split(".");
    if (
      decimalPart &&
      decimalPart.length >= MAX_DECIMAL_PLACES &&
      event.key !== "Backspace" &&
      event.key !== "Delete"
    ) {
      const cursorPosition =
        (event.target as HTMLInputElement).selectionStart || 0;
      const decimalPointIndex = inputValue.value.indexOf(".");
      if (cursorPosition > decimalPointIndex) {
        event.preventDefault();
      }
    }
  }
};

const formattedModelValue = computed(() => {
  return props.modelValue !== undefined
    ? formatAmount(props.modelValue.toString())
    : "";
});

watch(formattedModelValue, (newValue) => {
  if (inputValue.value !== newValue) {
    inputValue.value = newValue;
  }
});

onMounted(() => {
  inputValue.value = formattedModelValue.value;
});
</script>
