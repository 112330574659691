import apiClient, {
  bankingUrl,
  cryptoUrl,
  useRequestFilters,
  useRequestOptions,
} from "@/helpers/apiClient";
import {
  AccountRequestResponse,
  AccountsResponse,
  AssetsResponse,
  BankingTransactionResponse,
  PaginatedResponse,
  QueryKeys,
  RequestOptions,
  ServiceType,
  SupportedBankingCurrency,
  AccountListResponse,
} from "@/types";
import { useQuery } from "@tanstack/vue-query";
import { Ref } from "vue";

export const useListCorporateAccounts = (
  enabled: boolean = true,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: Record<string, Ref<any>>,
) =>
  useQuery<AccountListResponse[]>({
    queryKey: [QueryKeys.ACCOUNTS, ...Object.values(filters || {})],
    queryFn: () =>
      apiClient
        .get(bankingUrl(`accounts/corporate?${useRequestFilters(filters)}`))
        .then((res) => res.data.data),
    enabled,
  });

export const useListSupportedCurrencies = (enabled: boolean = true) =>
  useQuery<SupportedBankingCurrency[]>({
    queryKey: [QueryKeys.BANKING_SUPPORTED_CURRENCIES],
    queryFn: () =>
      apiClient
        .get(bankingUrl(`data/supported-currencies`))
        .then((res) => res.data.data),
    enabled,
  });

export const useGetAccount = (
  id: string,
  type: ServiceType,
  enabled: boolean = true,
) => {
  if (type === ServiceType.CRYPTO) {
    return useQuery<AssetsResponse>({
      queryKey: [QueryKeys.ASSET, id],
      queryFn: () =>
        apiClient.get(cryptoUrl(`assets/${id}`)).then((res) => res.data.data),
      enabled,
    });
  }

  return useQuery<AccountsResponse>({
    queryKey: [QueryKeys.ACCOUNT, id],
    queryFn: () =>
      apiClient.get(bankingUrl(`accounts/${id}`)).then((res) => res.data.data),
    enabled,
  });
};

export const useGetBankAccount = (id: string, enabled: boolean = true) =>
  useQuery<AccountsResponse>({
    queryKey: [QueryKeys.ACCOUNT, id],
    queryFn: () =>
      apiClient.get(bankingUrl(`accounts/${id}`)).then((res) => res.data.data),
    enabled,
  });

export const useGetAccountTransactions = (
  id: string,
  options: RequestOptions,
  enabled: boolean = true,
) =>
  useQuery<PaginatedResponse<BankingTransactionResponse>>({
    queryKey: [
      QueryKeys.ACCOUNT_TRANSACTIONS,
      id,
      options.page,
      options.limit,
      ...Object.values(options.filters || {}),
    ],
    queryFn: () =>
      apiClient
        .get(
          bankingUrl(`accounts/${id}/payments?${useRequestOptions(options)}`),
        )
        .then((res) => res.data.data),
    enabled,
  });

export const useListAccountRequests = (enabled: boolean = true) =>
  useQuery<PaginatedResponse<AccountRequestResponse>>({
    queryKey: [QueryKeys.ACCOUNT_REQUEST],
    queryFn: () =>
      apiClient
        .get(bankingUrl(`account-requests/corporate`))
        .then((res) => res.data.data),
    enabled,
  });

export const useGetAccountRequest = (id: string, enabled: boolean = true) =>
  useQuery<AccountRequestResponse>({
    queryKey: [QueryKeys.ACCOUNT_REQUEST, id],
    queryFn: () =>
      apiClient
        .get(bankingUrl(`account-requests/${id}`))
        .then((res) => res.data.data),
    enabled,
  });
