<template>
  <div class="relative w-full h-full bg-white overflow-y-auto">
    <button
      class="border w-10 h-10 rounded-full flex items-center justify-center absolute right-5 top-5"
      @click="closeModal"
    >
      <close-icon />
    </button>

    <div class="w-full px-5 pt-5">
      <h2 class="text-lg font-bold capitalize mb-5">Exchange</h2>
    </div>

    <banking-exchange-payment-confirmation
      v-if="showConfirmation && quote"
      :quote="quote"
      :loading="loading"
      :make-payment="handleMakePayment"
      :cancel-payment="handleCancelPayment"
    />

    <banking-exchange-transaction-success
      v-else-if="successfulTxn"
      :transaction="successfulTxn"
    />

    <div v-else-if="txnError" class="py-5">
      <transaction-error-modal
        :close-modal="closeModal"
        :error-message="txnError"
      />
    </div>

    <div v-else class="w-full px-5 py-5 flex flex-col gap-y-5">
      <div class="w-full">
        <div class="text-sm font-medium text-primary mb-3">Source Account</div>
        <div
          class="w-full px-2 md:px-4 py-3 text-primary rounded-[5px] text-xs md:text-sm cursor-pointer bg-greyscale-1 font-[800]"
        >
          <div>
            <div class="flex items-center gap-x-1 mb-2 text-xs">
              <asset-type :asset="account.currency" no-title />
              {{ account.currency }}
            </div>
            <div class="text-text-secondary font-medium text-[10px] md:text-xs">
              Bal:
              {{
                formatAmountToMajor(account.balance.available, account.currency)
              }}
            </div>
          </div>
        </div>
      </div>

      <app-select
        v-bind="formFields.destinationCurrency"
        name="destinationCurrency"
        label="Destination Account"
        :error-message="fieldErrors.destinationCurrency"
        placeholder="Select currency"
        required
      >
        <option value="" disabled>Select an account</option>
        <option
          v-for="it in swapCurrencies"
          :key="it.blockchain ? it.currency + '-' + it.blockchain : it.currency"
          :value="
            it.blockchain
              ? it.currency + '-' + it.blockchain
              : it.currency + '-'
          "
        >
          {{ it.currency + ` ${it.blockchain ? `(${it.blockchain})` : ""}` }}
          {{ it.blockchain ? "Asset" : "Account" }}
        </option>
      </app-select>

      <amount-input
        v-model="amount"
        name="amount"
        label="Amount"
        :error-message="fieldErrors.amount"
        required
      />

      <banking-exchange-payment-summary
        v-if="rate"
        :exchange-rate="rate"
        :billing-amount="{
          amount: quote?.billing_amount.amount.toString() || '0',
          currency: quote?.billing_amount.currency || account.currency,
        }"
        :destination-amount="{
          amount: quote?.destination_amount?.amount.toString() || '0',
          currency:
            quote?.destination_amount.currency ||
            parseCurrency(values.destinationCurrency).currency,
        }"
      />

      <app-button
        :loading="submitting"
        :disabled="submitting || !quote"
        variant="primary"
        size="lg"
        type="submit"
        @click="handleShowConfirmation"
        >Proceed</app-button
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  AccountsResponse,
  BankingSwapTransactionQuoteResponse,
  BankingTransactionResponse,
  CurrentRateV2,
  QueryKeys,
} from "@/types";
import { formatAmountToMajor } from "@/helpers";
import { reactive, watch, computed, ref } from "vue";
import { useField, useForm } from "vee-validate";
import { useWriteResource } from "@/composables/use-resource";
import { bankingUrl } from "@/helpers/apiClient";
import { useAppToast } from "@/composables";
import { errorMessage } from "@/helpers/error";
import { currencyOf } from "@/helpers/currencies";
import { useQueryClient } from "@tanstack/vue-query";
import { debounce } from "lodash";

const props = defineProps<{
  closeModal: () => void;
  account: AccountsResponse;
}>();

interface FormFields {
  amount: number;
  destinationCurrency: string;
}

const fieldErrors = reactive({
  amount: "",
  destinationCurrency: "",
});

const quote = ref<BankingSwapTransactionQuoteResponse>();
const exchangeRate = ref<CurrentRateV2 | null>(null);
const showConfirmation = ref(false);
const successfulTxn = ref<BankingTransactionResponse>();
const txnError = ref<string>();
const queryClient = useQueryClient();

const { defineInputBinds, values } = useForm<FormFields>();

const { value: amount } = useField<number>("amount");

const formFields = reactive({
  destinationCurrency: defineInputBinds("destinationCurrency"),
});

const toast = useAppToast();

const swapCurrencies = computed(() => {
  return props.account.features?.exchanges.supported_currencies || [];
});

const { submitting, execute: getQuote } = useWriteResource(
  bankingUrl("quotes/exchange"),
  "post",
  {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const rate = computed(() => {
  if (quote.value) {
    return quote.value.fx_rate;
  }

  if (exchangeRate.value) {
    return exchangeRate.value;
  }
  return null;
});
const { submitting: loading, execute: makePayment } = useWriteResource(
  bankingUrl("payments/exchange"),
  "post",
  {
    successTitle: "Your payment is being processed",
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ACCOUNTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ACCOUNT, props.account.id],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ACCOUNT_TRANSACTIONS, props.account.id],
      });
    },
    onError: (err) => {
      quote.value = undefined;
      txnError.value = errorMessage(err);
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleCancelPayment = () => {
  quote.value = undefined;
  showConfirmation.value = false;
};

const isRequiredFieldsEmpty = () => {
  if (!values.amount) {
    fieldErrors.amount = "Amount is required";
  }

  if (!values.destinationCurrency) {
    fieldErrors.amount = "Destination currency is required";
  }

  return !values.amount || !values.destinationCurrency;
};

const handleGetQuote = async () => {
  if (!isRequiredFieldsEmpty()) {
    const amount =
      Number(values.amount) *
      10 ** currencyOf(props.account.currency).precision;

    const res = await getQuote({
      body: {
        source_account_id: props.account.id,
        fixed_side: "source",
        amount,
        destination_currency: parseCurrency(values.destinationCurrency)
          .currency,
      },
    });

    quote.value = res;
    exchangeRate.value = res.fx_rate;
  }
};

const handleGetExhangeRate = async () => {
  if (values.destinationCurrency) {
    const res = await getQuote({
      body: {
        source_account_id: props.account.id,
        fixed_side: "source",
        amount: 100,
        destination_currency: parseCurrency(values.destinationCurrency)
          .currency,
      },
    });
    exchangeRate.value = res.fx_rate;
  }
};

const handleShowConfirmation = () => {
  if (quote.value) {
    showConfirmation.value = true;
  }
};

const handleMakePayment = async () => {
  if (quote.value) {
    const amount =
      Number(values.amount) *
      10 ** currencyOf(props.account.currency).precision;

    const txn = await makePayment({
      body: {
        source_account_id: props.account.id,
        fixed_side: "source",
        amount,
        destination_currency: parseCurrency(values.destinationCurrency)
          .currency,
        blockchain: parseCurrency(values.destinationCurrency).blockchain,
      },
    });

    quote.value = undefined;

    successfulTxn.value = txn;
  }
};

const parseCurrency = (v: string) => {
  if (!v) {
    return {
      currency: "",
      blockchain: undefined,
    };
  }
  return {
    currency: v.split("-")[0],
    blockchain: v.split("-")[1] || undefined,
  };
};

watch(
  () => values.amount,
  debounce(() => {
    if (Number(values.amount) > 0) {
      handleGetQuote();
      fieldErrors.amount = "";
    } else {
      quote.value = undefined;
    }
  }, 500),
);

watch(
  () => values.destinationCurrency,
  (val) => {
    exchangeRate.value = null;
    quote.value = undefined;

    if (val) {
      handleGetExhangeRate();
    }
    fieldErrors.destinationCurrency = "";
  },
);
</script>
