<template>
  <div class="w-full px-5 py-6 bg-white rounded-[5px] shadow-sm">
    <div class="flex justify-between items-center mb-5">
      <h2 class="text-primary text-base font-[800]">Confirm your payment</h2>
      <button @click="cancelPayment">
        <close-icon />
      </button>
    </div>
    <div class="bg-[#F9F9F9] px-3 my-5">
      <div
        class="flex justify-between items-center border-b border-[#F0F0F0] py-4"
      >
        <div class="text-[#9291A5] text-sm font-medium">You give</div>
        <div class="text-primary text-sm font-medium">
          {{ currencyOf(quote.sourceCurrency).symbol }}{{ quote.sourceAmount }}
        </div>
      </div>
      <div
        class="flex justify-between items-center border-b border-[#F0F0F0] py-4"
      >
        <div class="text-[#9291A5] text-sm font-medium">You receive</div>
        <div class="text-primary text-sm font-medium">
          {{ currencyOf(quote.destinationCurrency).symbol
          }}{{ quote.destinationAmount }}
        </div>
      </div>
    </div>
    <ul class="w-full flex flex-col gap-y-3">
      <li
        v-if="quote.exchangeRate && isExchangeRateMajor"
        class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
      >
        <div class="text-primary/60">Exchange rate</div>
        <div class="text-primary text-right">
          1 {{ quote?.destinationCurrency }} ≈
          {{ quote.exchangeRate }}
          {{ quote?.sourceCurrency }}
        </div>
      </li>

      <li
        v-else-if="quote.exchangeRate && !isExchangeRateMajor"
        class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
      >
        <div class="text-primary/60">Exchange rate</div>
        <div class="text-primary text-right">
          1 {{ quote?.destinationCurrency }} ≈
          {{ (1 / Number(quote.exchangeRate)).toFixed(0) }}
          {{ quote?.sourceCurrency }}
        </div>
      </li>

      <li
        class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
      >
        <div class="text-primary/60">Fees</div>
        <div class="text-primary text-right">
          {{ currencyOf(quote.feeCurrency).symbol }}{{ quote.feeAmount }}
        </div>
      </li>
      <li
        class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
      >
        <div class="text-primary/60">Destination</div>
        <div class="text-primary text-right">
          {{ quote.destinationCurrency }}
          {{
            currencyOf(quote.destinationCurrency).crypto ? "wallet" : "account"
          }}
        </div>
      </li>
    </ul>

    <div class="flex flex-col gap-y-3 mt-5">
      <app-button
        :loading="loading"
        variant="primary"
        size="lg"
        @click="makePayment"
        >Confirm</app-button
      >
      <app-button variant="outlined" size="lg" @click="cancelPayment"
        >Cancel</app-button
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { SwapTransactionQuote } from "./types";
import { currencyOf } from "@/helpers/currencies";

const props = defineProps<{
  cancelPayment: () => void;
  makePayment: () => void;

  quote: SwapTransactionQuote;

  loading: boolean;
}>();

const isExchangeRateMajor = computed(() => {
  if (props.quote.exchangeRate) {
    return Number(props.quote.exchangeRate) * 100 >= 1;
  }

  return false;
});
</script>
