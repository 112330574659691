<template>
  <div
    class="relative w-full bg-white rounded-[12px] overflow-hidden shadow-sm"
  >
    <header class="w-full flex items-center justify-between px-5 py-5">
      <h3 class="text-primary font-[800]">Today's rate</h3>

      <button class="inline w-[16px] h-[18px]" @click="closeModal">
        <close-icon />
      </button>
    </header>

    <page-loader v-if="isLoading" mini />
    <error-component
      v-else-if="isError"
      message="Error fetching current fx rates"
    />

    <div v-else-if="data" class="w-full">
      <table class="w-full table text-sm">
        <caption class="sr-only">
          Currency Exchange Rates
        </caption>
        <thead>
          <tr class="bg-[#ECECEC33] text-primary/60 font-light">
            <th scope="col" class="text-left pl-5 pr-5 py-2 text-gray-400">
              Currency Pair
            </th>
            <th scope="col" class="text-right pl-5 pr-5 py-2">You Pay</th>
            <th scope="col" class="text-right px-5 py-2">You Receive</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr
            v-for="rate in data"
            :key="rate.pair"
            class="border-b border-primary/20 border-dashed last:border-0"
          >
            <td
              class="font-medium text-primary text-left pl-5 pr-5 py-5 text-md"
              style="font-size: 0.95rem"
            >
              {{ rate.pair }}
            </td>
            <td class="text-primary/80 pl-5 pr-5 py-5 text-right">
              <span class="font-bold text-md" style="font-size: 0.95rem">{{
                rate.sell ? formatRateValue(rate.sell) : "N/A"
              }}</span>
              <span v-if="rate.sell" class="block text-xs text-gray-400"
                >To buy 1.00 {{ getRateCurrencies(rate).quote }}
              </span>
            </td>
            <td class="text-primary/80 px-5 py-5 text-right">
              <span class="font-bold text-md" style="font-size: 0.95rem">{{
                rate.buy ? formatRateValue(rate.buy) : "N/A"
              }}</span>
              <span v-if="rate.buy" class="block text-xs text-gray-400"
                >To sell 1.00 {{ getRateCurrencies(rate).quote }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="mostRecentUpdateTime"
        class="bg-[#F9F9F9] text-primary/70 text-center text-xs font-medium py-4"
      >
        Last updated: {{ formatDateTime(new Date(mostRecentUpdateTime)) }}
      </div>
      <div class="bg-[#3c3c3c] text-white pt-2 pb-2">
        <div class="p-2 pl-5">
          <span class="block text-sm">You pay:</span>
          <span class="block text-xs text-gray-400"
            >The rate we use when you want to buy the second currency
          </span>
        </div>

        <!-- dotted line-->
        <div class="p-2 pl-5">
          <div class="border-b border-gray-400 border-dashed"></div>
        </div>

        <div class="p-2 pl-5">
          <span class="block text-sm">You receive:</span>
          <span class="block text-xs text-gray-400"
            >The rate we use when you want to sell the second currency
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useGetCurrentFxRates } from "@/data-access/fx-rates";
import { formatDateTime, formatRateValue } from "@/helpers";
import { CurrentRateV2 } from "@/types";
import { computed } from "vue";

defineProps<{
  closeModal: () => void;
}>();

const { data, isLoading, isError } = useGetCurrentFxRates();

const mostRecentUpdateTime = computed(() => {
  if (data.value && data.value.length > 0) {
    return data.value.reduce((acc, curr) => {
      return new Date(acc.timestamp) > new Date(curr.timestamp) ? acc : curr;
    }).timestamp;
  }
  return null;
});

const getRateCurrencies = (rate: CurrentRateV2) => {
  return {
    base: rate.pair.split("/")[0],
    quote: rate.pair.split("/")[1],
  };
};
</script>
