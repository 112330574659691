<template>
  <div class="w-full mt-5">
    <header class="flex justify-between items-center w-full px-5 py-4">
      <h2 class="text-primary text-base font-[800]">Transactions</h2>
      <app-button
        v-if="hasPermission(LyncPermissions.statements_read)"
        variant="primary"
        size="md"
        @click="toggleStatementModal"
      >
        <download-icon />
        Export statement
      </app-button>
    </header>
    <app-table
      v-if="!isError"
      :data="transactions?.items"
      :columns="columns"
      :loading="isLoading"
      :pagination="{
        currentPage,
        perPage,
        totalItems: transactions?.total_items || 0,
      }"
      header-background
      @mobile-row-click="handleMobileRowClicked"
      @change="handleTableChange"
    >
      <template #column-status="props">
        <transaction-status :status="props.row.state" />
      </template>
      <template #column-amount="props">
        <div v-if="props.row.type === 'DEPOSIT'" class="font-bold">
          {{
            `${props.row.destination_amount.currency} ${formatAmount(
              props.row.destination_amount.value,
            )}`
          }}
        </div>
        <div v-else-if="props.row.type === 'WITHDRAWAL'" class="font-bold">
          {{
            `${props.row.source_amount.currency} ${formatAmount(
              props.row.source_amount.value,
            )}`
          }}
        </div>
        <div v-else-if="props.row.type === 'EXCHANGE'" class="font-bold">
          {{
            props.row.source.id === assetId
              ? `${props.row.source_amount.currency} ${formatAmount(
                  props.row.source_amount.value,
                )}`
              : `${props.row.destination_amount.currency} ${formatAmount(
                  props.row.destination_amount.value,
                )}`
          }}
        </div>
      </template>
      <template #column-type="props">
        <div class="flex gap-x-1 items-center">
          <credit-icon v-if="props.row.type === 'DEPOSIT'" />
          <exchange-icon v-else-if="props.row.type === 'EXCHANGE'" />
          <debit-icon v-else />

          <span
            class="capitalize leading-[0px]"
            :class="
              props.row.type === 'DEPOSIT'
                ? 'text-[#297FB0]'
                : props.row.type === 'EXCHANGE'
                  ? 'text-processing/70'
                  : 'text-[#B96B6B]'
            "
            >{{ props.row.type?.toLowerCase() }}</span
          >
        </div>
      </template>
      <template #column-beneficiary="props">
        <div
          v-if="
            props.row.destination.type === 'COUNTERPARTY' &&
            props.row.destination.counterparty
          "
        >
          <div class="mb-1 flex gap-x-2 items-start">
            <institution-icon
              v-if="props.row.destination.counterparty.type === 'FIAT'"
            />
            <wallet-icon
              v-if="props.row.destination.counterparty.type === 'CRYPTO'"
            />
            <div class="-mt-1">
              <div>
                {{ props.row.destination.counterparty.name.toLowerCase() }}
              </div>
              <div
                v-if="props.row.destination.counterparty.type === 'CRYPTO'"
                class="break-all text-xs text-text-secondary"
              >
                {{
                  maskAddress(
                    props.row.destination.counterparty.crypto_details.address,
                  )
                }}
              </div>
              <div
                v-if="props.row.destination.counterparty.type === 'FIAT'"
                class="break-all text-xs text-text-secondary"
              >
                {{
                  props.row.destination.counterparty.fiat_details.details
                    .accountNumber ||
                  props.row.destination.counterparty.fiat_details.details
                    .account_number
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="
            props.row.destination.type === 'ASSET' &&
            props.row.destination.asset
          "
        >
          <div
            v-if="
              props.row.source.type === 'COUNTERPARTY' &&
              props.row.source.counterparty
            "
          >
            <div
              v-if="props.row.source.counterparty.type === 'CRYPTO'"
              class="flex gap-x-2 items-center"
            >
              <wallet-icon />
              <div
                v-if="props.row.source.counterparty.crypto_details"
                class="break-all"
              >
                {{
                  maskAddress(
                    props.row.source.counterparty.crypto_details.address,
                  )
                }}
              </div>
            </div>
            <div
              v-if="props.row.source.counterparty.type === 'FIAT'"
              class="flex gap-x-2 items-start"
            >
              <institution-icon />
              <div>
                <div v-if="props.row.source.counterparty.name">
                  {{ props.row.source.counterparty.name.toLowerCase() }}
                </div>
                <div
                  v-if="props.row.source.counterparty.fiat_details"
                  class="break-all text-xs text-text-secondary"
                >
                  {{
                    maskAddress(
                      props.row.source.counterparty.crypto_details.address,
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="props.row.destination.type === 'LYNC_BANK_ACCOUNT'"
          class="text-sm"
        >
          {{ props.row.destination_amount.currency }} Account
        </div>
      </template>
      <template #column-action="props">
        <button
          class="underline underline-offset-2"
          @click="openTransactionDetails(props.row.id)"
        >
          View
        </button>
      </template>

      <!-- mobile columns -->

      <template #column-mobile-account="props">
        <div class="flex justify-between items-start gap-x-3">
          <div class="">
            <credit-icon v-if="props.row.type === 'DEPOSIT'" />
            <exchange-icon v-else-if="props.row.type === 'EXCHANGE'" />
            <debit-icon v-else />
          </div>

          <div>
            <div v-if="props.row.type === 'DEPOSIT'">
              <div class="flex items-center gap-x-1">
                <asset-type :asset="props.row.destination?.asset?.currency" />
                <span v-if="props.row.destination.asset?.blockchain"
                  >({{ props.row.destination.asset?.blockchain }})</span
                >
              </div>
              <div
                v-if="props.row.destination.address"
                class="mt-1 text-text-secondary ml-6 text-xs"
              >
                {{ maskAddress(props.row.destination.address) }}
              </div>
            </div>
            <div v-else-if="props.row.type === 'WITHDRAWAL'">
              <div class="flex items-center gap-x-1">
                <asset-type :asset="props.row.asset" />
                <span v-if="props.row.source.asset?.blockchain"
                  >({{ props.row.source.asset?.blockchain }})</span
                >
              </div>
              <div
                v-if="props.row.source.address"
                class="mt-1 text-text-secondary ml-6 text-xs"
              >
                {{ maskAddress(props.row.source.address) }}
              </div>
            </div>
            <div v-else-if="props.row.type === 'EXCHANGE'">
              <div v-if="props.row.source.id === assetId" class="flex gap-x-1">
                <asset-type :asset="props.row.source_amount.currency" />
              </div>
              <div v-else class="flex gap-x-1">
                <asset-type :asset="props.row.destination_amount.currency" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #column-mobile-amount="props">
        <div v-if="props.row.type === 'DEPOSIT'">
          <div class="font-bold">
            {{
              `${props.row.destination_amount.currency} ${formatAmount(
                props.row.destination_amount.value,
              )}`
            }}
          </div>
          <div class="mt-1 text-xs text-right">
            {{ formatDate(props.row.created_date) }}
          </div>
        </div>
        <div v-else-if="props.row.type === 'WITHDRAWAL'">
          <div class="font-bold">
            {{
              `${props.row.source_amount.currency} ${formatAmount(
                props.row.source_amount.value,
              )}`
            }}
          </div>
          <div class="mt-1 text-xs text-right">
            {{ formatDate(props.row.created_date) }}
          </div>
        </div>
        <div v-else-if="props.row.type === 'EXCHANGE'">
          <div class="font-bold">
            {{
              props.row.source.id === assetId
                ? `${props.row.source_amount.currency} ${formatAmount(
                    props.row.source_amount.value,
                  )}`
                : `${props.row.destination_amount.currency} ${formatAmount(
                    props.row.destination_amount.value,
                  )}`
            }}
          </div>
          <div class="mt-1 text-xs text-right">
            {{ formatDate(props.row.created_date) }}
          </div>
        </div>
      </template>
      <template #empty-state>
        <div
          class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
        >
          <empty-data />
          <h4 class="font-bold text-base lg:text-lg">No transactions</h4>
          <p class="text-base text-center text-text-primary">
            Make your first payment to a beneficiary, or swap your stableicon
            the fastest way
          </p>
        </div>
      </template>
    </app-table>
    <error-component
      v-else-if="isError"
      message="Error fetching transactions"
    />
  </div>
  <app-modal
    size="xl"
    :is-open="isStatementModalOpen"
    :handle-close="toggleStatementModal"
    align-right
  >
    <export-statement
      :close-modal="toggleStatementModal"
      :asset-id="assetId"
      :service-type="ServiceType.CRYPTO"
    />
  </app-modal>
  <transaction-details
    v-if="activeTransactionId"
    :id="activeTransactionId"
    :type="ServiceType.CRYPTO"
    :ext-close-modal="closeTransactionDetails"
  />
</template>

<script lang="ts" setup>
import {
  TableChangeParams,
  TableColumn,
} from "@/components/shared/table/table.props";
import { useGetAssetTransactions } from "@/data-access/assets";
import { formatAmount, formatDate, maskAddress } from "@/helpers";
import { CryptoTransactionResponse, ServiceType } from "@/types";
import { ref } from "vue";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";

const componentProps = defineProps<{
  assetId: string;
}>();

const currentPage = ref(1);
const perPage = ref(10);
const isStatementModalOpen = ref(false);
const activeTransactionId = ref<string | null>(null);
const { hasPermission } = usePermission();

const toggleStatementModal = () => {
  isStatementModalOpen.value = !isStatementModalOpen.value;
};

const openTransactionDetails = (id: string) => {
  activeTransactionId.value = id;
};

const closeTransactionDetails = () => {
  activeTransactionId.value = null;
};

const {
  isLoading,
  data: transactions,
  isError,
} = useGetAssetTransactions(componentProps.assetId, {
  page: currentPage,
  limit: perPage,
});

const columns: TableColumn<
  CryptoTransactionResponse & {
    action: string;
  }
>[] = [
  {
    label: "Date",
    selector: (row) => formatDate(row.created_date),
    dataIndex: "date",
    showOnMobile: false,
  },
  {
    label: "Amount",
    selector: () => {},
    dataIndex: "amount",
    showOnMobile: false,
  },
  {
    label: "Type",
    selector: (row) =>
      `${row.direction.toLowerCase() === "outbound" ? "Debit" : "Credit"}`,
    dataIndex: "type",
    showOnMobile: false,
  },
  {
    label: "Counterparty",
    selector: (row) =>
      row.destination.type === "COUNTERPARTY"
        ? row.destination.counterparty?.name.toLowerCase()
        : "N/A",
    dataIndex: "beneficiary",
    showOnMobile: false,
  },

  {
    label: "Status",
    selector: (row) => row.state,
    dataIndex: "status",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-account",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "mobile-amount",
    showOnMobile: true,
  },
];

const handleMobileRowClicked = (row: CryptoTransactionResponse) => {
  openTransactionDetails(row.id);
};

const handleTableChange = (params: TableChangeParams) => {
  currentPage.value = params.currentPage;
  perPage.value = params.perPage;
};
</script>
