<template>
  {{ rateDisplay() }}
</template>

<script lang="ts" setup>
import { formatRateValue } from "@/helpers";
import { CurrentRateV2, ExchangeRate } from "@/types";
import { computed } from "vue";

const props = defineProps<{
  rate: CurrentRateV2;
  sendingCurrency: string;
}>();

const fxRate = computed(() => {
  const r = new ExchangeRate(props.rate);
  if (r.buy?.lessThan(0.01) || r.sell?.lessThan(0.01)) {
    return r.invert();
  }
  return r;
});

const rateDisplay = () => {
  const rate = fxRate.value;
  const source = props.sendingCurrency as string;

  const pair = {
    source: rate.pair.split("/")[0],
    destination: rate.pair.split("/")[1],
  };

  if (source === pair.source) {
    return `${formatRateValue(rate.sell)} ${pair.source} = 1 ${
      pair.destination
    }`;
  }

  if (source === pair.destination) {
    return `1 ${pair.destination} = ${formatRateValue(rate.buy)} ${
      pair.source
    }`;
  }

  return "";
};
</script>
