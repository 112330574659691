import { formatRateValue } from "@/helpers";
import Decimal from "decimal.js";
import { Ref } from "vue";

export enum ServiceType {
  CRYPTO = "crypto",
  BANKING = "banking",
}

export interface ResponseData<T> {
  data: PaginatedResponse<T>;
  meta: {
    filters: {
      key: string;
      type: string;
      values?: string[];
    }[];
  };
}

export interface PaginatedResponse<T> {
  items: T[];
  page: number;
  limit: number;
  total_items: number;
  total_pages: number;
}

export interface RequestOptions {
  page: Ref<number>;
  limit: Ref<number>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: Record<string, Ref<any>>;
}

export type TAddress = {
  line_1: string;
  line_2?: string;
  city: string;
  state: string;
  postal_code: string;
};

export interface IAsset {
  currency: string;
  blockchain: string;
}

export interface IDepositAddress {
  address: string;
  type: string;
  qr_code_link: string;
}

export interface AssetsResponse {
  id: string;
  state: string;
  created_date: string;
  updated_date: string;
  name: string;
  type: string;
  asset: IAsset;
  wallet_id: string;
  deposit_address: IDepositAddress;
  precision: number;
  balance: string;
  balance_in_usd: string;
  features?: AssetFeatures;
}

export interface TotalAssetValueResponse {
  total_usd_value: string;
  total_assets_count: string;
}

export interface TransactionsSummaryData {
  data: {
    range: {
      start: string;
      end: string;
    };
    inbound: string;
    outbound: string;
    currency: string;
    label: string;
  }[];
  totals: {
    inbound: string;
    outbound: string;
  };
}

export interface BankingTransactionsSummaryData {
  data: {
    range: {
      start: string;
      end: string;
    };
    inbound: number;
    outbound: number;
    currency: string;
    label: string;
  }[];
  totals: {
    inbound: number;
    outbound: number;
  };
}

export interface AssetTransactionsSummaryResponse {
  last_week: TransactionsSummaryData;
  last_6_months: TransactionsSummaryData;
  last_year: TransactionsSummaryData;
}

export interface AccountTransactionsSummaryResponse {
  last_week: BankingTransactionsSummaryData;
  last_6_months: BankingTransactionsSummaryData;
  last_year: BankingTransactionsSummaryData;
}

export interface AvailableAssetsResponse {
  currency: string;
  blockchain: string;
}

export interface WalletBeneficiaryRequest {
  name: string;
  address: string;
  currency: string;
  blockchain: string;
}

export enum KycStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
}

export enum QueryKeys {
  ASSETS = "assets",
  ASSET = "asset",
  ASSET_TRANSACTIONS = "asset-transactions",
  ASSET_TRANSACTIONS_SUMMARY = "asset-transactions-summary",
  TOTAL_ASSETS_VALUE = "total-assets-value",
  AVAILABLE_ASSETS = "available-assets",

  ACCOUNT_TRANSACTIONS_SUMMARY = "account-transactions-summary",

  CRYPTO_BENEFICIARIES = "beneficiaries",
  BANKING_BENEFICIARIES = "banking-beneficiaries",

  SINGLE_CRYPTO_BENEFICIARY = "crypto-beneficiary",
  SINGLE_BANKING_BENEFICIARY = "banking-beneficiary",

  CRYPTO_SUPPORTED_COUNTRIES = "crypto-supported-countries",
  BANKING_SUPPORTED_COUNTRIES = "banking-supported-countries",

  BANKING_SUPPORTED_PAYMENT_METHODS = "banking-supported-payment-methods",
  CRYPTO_SUPPORTED_PAYMENT_METHODS = "crypto-supported-payment-methods",

  BANKING_TRANSACTIONS = "banking-transactions",
  CRYPTO_TRANSACTIONS = "crypto-transactions",

  SINGLE_BANKING_TRANSACTION = "banking-transaction",
  SINGLE_CRYPTO_TRANSACTION = "crypto-transaction",

  TRANSACTION_DOCUMENTATION = "transaction-documentation",

  ACCOUNTS = "accounts",
  ACCOUNT_TRANSACTIONS = "account-transactions",
  ACCOUNT = "account",
  ACCOUNT_REQUEST = "account-request",
  MFA = "mfa",

  CRYPTO_STATEMENTS = "crypto-statements",
  BANKING_STATEMENTS = "banking-statements",

  BANKING_SUPPORTED_CURRENCIES = "banking-supported-currencies",

  USER_INVITES = "user-invites",
  USERS = "users",
  ROLES = "roles",

  APPROVAL_REQUESTS = "approval-requests",

  FX_RATES = "fx-rates",
  CURRENT_FX_RATES = "current-fx-rates",

  BUSINESS_DEALS = "business-deals",

  SINGLE_DEAL = "single-deal",
}

export interface BeneficiaryResponse {
  id: string;
  state: string;
  type: string;
  role: string;
  name?: string;
  friendly_name?: string;
  email?: string;
  currency: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  details: Record<string, any>;
  address?: string | TAddress;
  address_details?: TAddress;
  country?: string;
}

export interface SupportedCountriesData {
  code: string;
  name: string;
  methods: {
    currency: string;
    payment_methods: string[];
  }[];
}

export interface PaymentMethodRequirement {
  key: string;
  type: string;
  required: boolean;
  description: string;
  allowed_values?: string[];
}

export interface SupportedPaymentMethodsData {
  type: string;
  description: string;
  requirements: PaymentMethodRequirement[];
}

export interface NgnBanksData {
  nip_code: string;
  bank_code?: string;
  name: string;
}

export interface NgnAccountValidationData {
  nip_code: string;
  account_name: string;
  account_number: string;
}

export enum IdentityKycVerificationState {
  CREATED = "CREATED",
  READY = "READY",
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  COMPLETED = "COMPLETED",
}
export enum ReviewStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  CORRECTION_REQUESTED = "CORRECTION_REQUESTED",
  REJECTED = "REJECTED",
}

export interface BusinessResponse {
  id: string;
  name: string;
  email: string;
  business: {
    id: string;
    name: string;
    phone: string;
    email: string;
    country: string;
    state: string;
    kyc_status: string;
    institution_id: string;
    core_identity_id: string;
    features: string[];
    permissions: string[];
    created_date: string;
  };
  features: string[];
  kyc?: {
    id: string;
    state: IdentityKycVerificationState;
    review_status: ReviewStatus;
    created_date: string;
    updated_date: string;
    verification: {
      type: string;
      url: string;
      expiry: string;
    };
  };
}

export interface AccountDepositAddressData {
  bank_name: string;
  account_type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bank_address: Record<string, any>;
  account_number: string;
  routing_number?: string;
  swift_code?: string;
}

export interface AccountDepositAddress {
  type: string;
  data: Record<string, string>;
}

export interface AccountFeatures {
  transfers: boolean;
  exchanges: {
    supported_currencies: {
      currency: string;
      blockchain?: string;
    }[];
  };
}

export interface AssetFeatures {
  transfers: boolean;
  exchanges: {
    supported: {
      currency: string;
      blockchain?: string;
    }[];
  };
}

export interface AccountListResponse {
  id: string;
  state: string;
  created_date: string;
  updated_date: string;
  currency: string;
  wallet_id: string;
  name?: string;
  balance?: {
    available: number;
    ledger: number;
  };
  deposit_addresses?: AccountDepositAddress[];
  features?: AccountFeatures;
  account_request_id?: string;
  in_request_state?: boolean;
  has_completed_tasks?: boolean;
}

export interface AccountsResponse {
  id: string;
  state: string;
  created_date: string;
  updated_date: string;
  currency: string;
  wallet_id: string;
  name: string;
  balance: {
    available: number;
    ledger: number;
  };
  deposit_addresses: AccountDepositAddress[];
  features?: AccountFeatures;
}

export interface IAccount {
  crypto_account?: AssetsResponse;
  banking_account?: AccountListResponse;
}

export interface SupportedBankingCurrency {
  code: string;
  name: string;
  symbol: string;
  precision: number;
  crypto: boolean;
  requirements?: {
    name: string;
    type: string;
    regex: string | null;
  }[];
}

export enum AccountRequestTaskType {
  WAZA_AGREEMENT = "WAZA_AGREEMENT",
  AGREEMENT = "AGREEMENT",
  PARTNER_AGREEMENT = "PARTNER_AGREEMENT",
  DECLARATION_OF_SOURCE_OF_FUNDS = "DECLARATION_OF_SOURCE_OF_FUNDS",
  PHONE_OTP = "PHONE_OTP",
  EMAIL_OTP = "EMAIL_OTP",
}

export interface AccountRequestTaskData {
  otp_code?: {
    phone?: string;
    email?: string;
    completion_date?: string;
  };
  agreement?: {
    id: string;
    description: string;
    type: string;
    acceptance_date?: string;
  };
  declaration?: {
    text: string;
    acceptance_date: string;
  };
}

export interface AccountRequestTask {
  id: string;
  created_date: string;
  updated_date: string;
  type: AccountRequestTaskType;
  data: AccountRequestTaskData;

  state: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes: Record<string, any>;
}

export interface AccountRequestResponse {
  id: string;
  created_date: string;
  updated_date: string;
  owner_name: string;
  wallet_id: string;
  currency: string;
  state: string;
  tasks: AccountRequestTask[];
}

export interface CreateAccountRequest {
  currency: string;
}

export interface CounterpartyDetails {
  name: string;
  id: string;
  type: string;
  crypto_details?: {
    address: string;
    blockchain: string;
  };
  fiat_details?: {
    type: string;
    details: {
      bank_name: string;
      account_type: string;
      bank_address: string;
      account_number: string;
      routing_number: string;
    };
  };
  role: string;
  currency: string;
  blockchain: string;
  beneficiary_address: string;
}

export interface CryptoTransactionResponse {
  id: string;
  state: string;
  created_date: string;
  source: {
    id: string;
    type: string;
    address?: string;
    asset?: {
      name: string;
      id: string;
      currency: string;
      blockchain: string;
    };
    counterparty?: CounterpartyDetails;
  };
  destination: {
    id: string;
    type: string;
    counterparty?: CounterpartyDetails;
    blockchain?: string;
    address?: string;
    asset?: {
      name: string;
      id: string;
      currency: string;
      blockchain: string;
    };
  };
  type: string;
  source_amount: {
    value: string;
    currency: string;
  };
  destination_amount: {
    value: string;
    currency: string;
  };
  fee: {
    value: string;
    currency: string;
  };
  fee_breakdown?: {
    amount: string;
    description: string;
    currency: string;
  }[];
  asset: string;
  completion_date: string;
  direction: string;
  description: string;
  txn_hash?: string;
  documentation: ITransactionDocumentation[];
  status_description?: string;
}

export interface ITransactionDocumentation {
  id: string;
  type: string;
  value?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  file?: any;
  description: string;
}

export interface BankingTransactionDocumentation {
  id: string;
  type: string;
  value?: string;
  file_info?: {
    type: string;
    name: string;
    size: number;
    url: string;
  };
  description: string;
  payment_id?: string;
  transaction_id?: string;
}

export interface BankingTransactionResponse {
  id: string;
  state: string;
  created_date: string;
  updated_date: string;
  source: {
    id: string;
    type: string;
    counterparty?: {
      type: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      details: Record<string, any>;
    };
  };
  destination: {
    id: string;
    type: string;
    name?: string;
    counterparty?: {
      type: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      details: Record<string, any>;
    };
  };
  source_amount: number;
  source_currency: string;
  sending_amount: number;
  sending_currency: string;
  billing_amount: number;
  billing_currency: string;
  total_fee_amount: number;
  total_fee_currency: string;
  fee_breakdown?: {
    amount: number;
    currency: string;
    description: string;
  }[];
  destination_amount: number;
  destination_currency: string;
  currency: string;
  reference: string;
  identifiers?: {
    id: string;
    type: string;
  }[];
  memo?: string;
  balance_before?: number;
  balance_after?: number;
  completion_date?: string;
  client_reference?: string;
  description: string;
  type: string;
  tags?: string[];
  failure_reason?: string;
}
export interface StatementsResponse {
  id: string;
  created_at: string;
  updated_at: string;
  state: string;
  from: string;
  to: string;
  format: string;
  assetId: string;
}

export interface Money<t> {
  currency: string;
  amount: t;
}

export interface QuoteResponse {
  id: string;
  source_currency: string;
  source_asset_id: string;
  fee: Money<number>;
  destination_amount: Money<number>;
  source_amount: Money<number>;
  balance_before: string;
  balance_after: string;
  destination: {
    id: string;
    type: string;
  };
  expiry_date: string;
  created_date: string;
  exchange?: CurrentRateV2;
}

export interface BankingQuoteResponse {
  destination_amount: Money<number>;
  billing_amount: Money<number>;
  sending_amount: Money<number>;
  total_fees: Money<number>;
  fee_breakdown: {
    amount: number;
    currency: string;
    description: string;
  }[];
  expiry_date?: string;
  id?: string;
  operation: string;
  exchange_rate?: string;
  fx_rate?: CurrentRateV2;
}

export interface BankingSwapTransactionQuoteResponse {
  destination_amount: Money<number>;
  billing_amount: Money<number>;
  total_fees: Money<number>;
  fee_breakdown: {
    amount: number;
    currency: string;
    description: string;
  }[];
  expiry_date?: string;
  id?: string;
  operation: string;
  exchange_rate?: string;
  source: {
    id: string;
    type: string;
  };
  destination: {
    id: string;
    type: string;
  };
  destination_currency: string;
  fx_rate?: CurrentRateV2;
}

export interface CryptoSwapTransactionQuoteResponse {
  id: string;
  source_currency: string;
  source_asset_id: string;
  source_amount: Money<string>;
  fee: Money<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fee_breakdown: any[];
  destination_amount: Money<string>;
  balance_before: string;
  balance_after: string;
  expiry_date: string;
  created_date: string;
  exchange: {
    rate: number;
  };
}

export interface PaymentEligibilityResponse {
  supported: false;
  reason?: string;
}
export interface MfaResponse {
  "2faEnabled": boolean;
}

export interface UserInvitesResponse {
  id: string;
  state: string;
  created_date: string;
  updated_date: string;
  email: string;
  business_id: string;
  position: string;
  roles: {
    id: string;
    name: string;
    scope: string;
    description: string;
  }[];
  business?: {
    id: string;
    name: string;
    state: string;
  };
}

export interface BusinessUsersResponse {
  id: string;
  business_user_id?: string;
  is_owner: boolean;
  state: string;
  email: string;
  first_name: string;
  last_name: string;
  roles: {
    id: string;
    name: string;
    description: string;
  }[];
  user?: {
    id: string;
    first_name: string;
    last_name: string;
    phone: string;
    state: string;
  };
  permissions: {
    action: string;
    description: string;
  }[];
}

export interface RolesResponse {
  id: string;
  state: string;
  created_date: string;
  updated_date: string;
  name: string;
  scope: string;
  description: string;
  permissions: {
    id: string;
    scope: string;
    action: string;
    description: string;
  }[];
}

export interface VerifyUserInviteResponse {
  id: string;
  email: string;
  state: string;
  business_id: string;
  position: string;
  user_id?: string | null;
  business: {
    id: string;
    name: string;
    state: string;
  };
}

interface ApprovalRequestCounterparty {
  id: string;
  name: string;
  email: string;
}

export enum WazaService {
  helios = "helios",
  centurion = "centurion",
  identity = "identity",
  iris = "iris",
  lync = "lync",
}

export interface ApprovalRequestResponse {
  id: string;
  approver: ApprovalRequestCounterparty;
  requester: ApprovalRequestCounterparty;
  scope: WazaService;
  amount: {
    currency: string;
    value: string;
  };
  actioned_at: string;
  actioned_justification: string;
  request_justification: string;
  entity_id: string;
  entity_type: string;
  state: string;
}

export interface FxRateResponse {
  id: string;
  state: string;
  created_date: string;
  base_currency: string;
  quote_currency: string;
  rate: number;
  effective_from: string;
  effective_to: string;
}

export interface FxRate {
  currencyPair: string;
  buyRate: number | null;
  sellRate: number | null;
  createdDate: string;
}

export interface DealQuestion {
  id: number;
  content: {
    en: string;
    fr: string;
  };
}

export interface BusinessDealsResponse {
  id: string;
  name: string;
  description: string;
  category: string;
  logo_url: string;
  benefits: string[];
  eligbility_questions: DealQuestion[];
  business_deal_state?: string;
  business_deal_id?: string;
}

export interface RedeemDealRequest {
  eligibility_questions: {
    id: number;
    question: string;
    answer: string;
  }[];
}

export interface DealRedemptionInstruction {
  redemption_instructions: string;
}

export interface CurrentRateV2 {
  pair: string;
  buy: string | null;
  sell: string | null;
  id: string;
  timestamp: number;
}

export class ExchangeRate {
  readonly pair: string;
  readonly buy: Decimal | null;
  readonly sell: Decimal | null;
  readonly id: string;
  readonly timestamp: number;

  constructor({ pair, buy, sell, id, timestamp }: CurrentRateV2) {
    this.pair = pair;
    this.buy = buy ? new Decimal(formatRateValue(buy)) : null;
    this.sell = sell ? new Decimal(formatRateValue(sell)) : null;
    this.id = id;
    this.timestamp = timestamp;
  }

  invert() {
    return new ExchangeRate({
      pair: this.pair.split("/")[1] + "/" + this.pair.split("/")[0],
      sell: this.buy === null ? null : this.buy.pow(-1).toString(),
      buy: this.sell === null ? null : this.sell.pow(-1).toString(),
      timestamp: this.timestamp,
      id: this.id,
    });
  }
}
