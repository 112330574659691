<template>
  <form
    class="w-full bg-white border-[#f0f0f0] py-4 rounded-[10px]"
    @submit.prevent="handleRedeemDeal"
  >
    <div class="w-full border-b border-[#f0f0f0] px-5">
      <div class="flex justify-between items-start mb-4">
        <div class="w-[74px] h-auto rounded-[10px] overflow-hidden">
          <img :src="avatar" :alt="name" />
        </div>

        <button @click="closeDealDetails">
          <close-icon />
        </button>
      </div>
      <div class="mb-4">
        <h3 class="text-base font-bold text-black">{{ name }}</h3>
        <p class="text-sm text-primary/60">{{ description }}</p>
      </div>
    </div>
    <div class="px-5 mt-5">
      <div class="w-full flex flex-col gap-y-2 mb-5">
        <div
          v-for="it in computedRewards"
          :key="it.description"
          class="text-[13px] text-primary flex gap-x-3"
        >
          <reward-icon class="min-w-fit mt-[2px]" />
          <div>
            <span v-if="it.title" class="font-semibold"> {{ it.title }}: </span>
            <span>{{ it.description }}</span>
          </div>
        </div>
      </div>

      <div
        v-if="eligibilityQuestions.length > 0"
        class="flex flex-col gap-y-5 mb-5"
      >
        <app-input
          v-for="it in eligibilityQuestions"
          :key="it.id"
          v-model="formState[it.id]"
          :label="it.content"
          type="text"
          required
        />
      </div>

      <app-button variant="primary" type="submit" size="lg" :loading="loading"
        >Get deal</app-button
      >
    </div>
  </form>
</template>

<script setup lang="ts">
import { RedeemDealRequest } from "@/types";
import { computed, reactive } from "vue";

export interface Props {
  id: string;
  name: string;
  state?: string;
  avatar: string;
  description: string;
  category: string;
  rewards: string[];
  eligibilityQuestions: { id: number; content: string }[];
  loading: boolean;
  redeemDeal: (request: RedeemDealRequest) => void;
  closeDealDetails: () => void;
}

const props = defineProps<Props>();

const formState = reactive<Record<number, string>>({});

const computedRewards = computed(() => {
  return props.rewards.map((reward) => {
    const value = reward.split(":");

    if (value.length > 1) {
      return {
        title: reward.split(":")[0],
        description: reward.split(":")[1],
      };
    }

    return {
      title: null,
      description: reward,
    };
  });
});

const handleRedeemDeal = () => {
  props.redeemDeal({
    eligibility_questions: props.eligibilityQuestions.map((it) => ({
      id: it.id,
      answer: formState[it.id],
      question: it.content,
    })),
  });
};
</script>
