import apiClient, { useRequestOptions } from "@/helpers/apiClient";
import {
  BusinessDealsResponse,
  DealRedemptionInstruction,
  PaginatedResponse,
  QueryKeys,
  RequestOptions,
} from "@/types";
import { keepPreviousData, useQuery } from "@tanstack/vue-query";

export const useGetBusinessDeals = (
  options: RequestOptions,
  enabled: boolean = true,
) => {
  return useQuery<PaginatedResponse<BusinessDealsResponse>>({
    queryKey: [
      QueryKeys.BUSINESS_DEALS,
      options.page,
      options.limit,
      ...Object.values(options.filters || {}),
    ],
    queryFn: () =>
      apiClient
        .get(`/deals?${useRequestOptions(options)}`)
        .then((res) => res.data.data),
    placeholderData: keepPreviousData,
    enabled,
  });
};

export const useGetSingleDeal = (id: string) => {
  return useQuery<BusinessDealsResponse>({
    queryKey: [QueryKeys.SINGLE_DEAL, id],
    queryFn: () => apiClient.get(`deals/${id}`).then((res) => res.data.data),
  });
};

export const useGetDealRedemptionInstruction = (id: string) => {
  return useQuery<DealRedemptionInstruction>({
    queryKey: [QueryKeys.SINGLE_DEAL, id],
    queryFn: () =>
      apiClient
        .get(`deals/${id}/redemption-instruction`)
        .then((res) => res.data.data),
  });
};
