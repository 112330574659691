<template>
  <div class="w-full bg-white py-4 px-5 relative rounded-[10px]">
    <page-loader v-if="isLoading" mini />
    <div v-else-if="data" class="w-full flex flex-col items-center gap-y-3">
      <div class="w-[127px] h-[127px] rounded-full">
        <img src="@/assets/images/coupon.png" alt="" />
      </div>

      <h4 class="text-center text-lg font-bold text-black">
        Your Deal is Ready!
      </h4>
      <p class="text-[#B47E30] bg-[#B47E30]/5 font-medium text-sm text-center">
        {{ data.redemption_instructions }}
      </p>

      <div class="w-full flex gap-x-2">
        <app-button variant="outlined"> <copy-icon /> Copy code </app-button>
        <app-button variant="primary" @click="handleClose">Close</app-button>
      </div>

      <button class="absolute top-2 right-2" @click="handleClose">
        <close-icon />
      </button>
    </div>
    <error-component
      v-else-if="error"
      :message="'Error getting redemption instruction'"
    />
  </div>
</template>

<script setup lang="ts">
import { useGetDealRedemptionInstruction } from "@/data-access/deals";

interface Props {
  dealId: string;
  handleClose: () => void;
}

const props = defineProps<Props>();

const { isLoading, data, error } = useGetDealRedemptionInstruction(
  props.dealId,
);
</script>
