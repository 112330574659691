<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3354 10.0901L14.2871 13.0412L13.3119 14.0164L10.3609 11.0646C9.26281 11.9448 7.89703 12.4236 6.48973 12.4216C3.06588 12.4216 0.287109 9.64282 0.287109 6.21898C0.287109 2.79513 3.06588 0.0163574 6.48973 0.0163574C9.91357 0.0163574 12.6923 2.79513 12.6923 6.21898C12.6943 7.62628 12.2156 8.99206 11.3354 10.0901ZM9.95286 9.57873C10.8275 8.67927 11.316 7.47358 11.314 6.21898C11.314 3.55392 9.15479 1.39472 6.48973 1.39472C3.82467 1.39472 1.66547 3.55392 1.66547 6.21898C1.66547 8.88404 3.82467 11.0432 6.48973 11.0432C7.74433 11.0452 8.95002 10.5568 9.84948 9.68211L9.95286 9.57873Z"
      fill="#BABABA"
    />
  </svg>
</template>
